@import 'react-dadata/dist/react-dadata.css';

html,
body,
#root {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
    background: linear-gradient(212.78deg, #EBF5FF 21.68%, #EAE8FD 94.47%);
    font-family: 'Manrope', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
    --sat: env(safe-area-inset-top);
    --sar: env(safe-area-inset-right);
    --sab: env(safe-area-inset-bottom); /* THIS ONE GETS US THE HOME BAR HEIGHT */
    --sal: env(safe-area-inset-left);

    --color-primary: #0260F9
    /**#1CA1C1/**/
    ;
    --color-secondary: #EEE0CB;
    --color-error: #DC3522;
    --color-success: #5CA595;
    --color-background: #EEE0CB;
    --color-button: rgba(0, 0, 0, 0.7);
}

@media (min-width : 834px) {
    ::-webkit-scrollbar {
        width: .5rem;
        height: .5rem;
        border-radius: .5rem;
        background-color: rgba(25, 118, 210, 0.08);
    }

    ::-webkit-scrollbar-thumb {
        background-color: var(--mui-palette-primary-main, #0260F9);
        border-radius: 10px;
    }

    ::-webkit-scrollbar-track-piece {
        background-color: #D2D8E8;
        border: calc(.25rem - .5px) solid #fff;
    }
}

a button {
  text-decoration: none;
}

textarea {
  font-size: 1.3rem;
}

.react-dadata__input {
  border: initial;
  border-radius: initial;
  box-sizing: initial;
  display: initial;
  font-size: initial;
  height: initial;
  outline: initial;
  padding: initial;
  width: initial;
}

#root .leaflet-attribution-flag {
  display: none !important;
}

.react-joyride__beacon>span:first-child {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.react-joyride__beacon>span:first-child::after {
  content: "?";
  color: #fff;
  font-weight: bold;
}

.react-joyride__tooltip a[data-route]{
  font-weight: 600;
}

.marker-icon {
    border: 1px solid rgba(76, 175, 80, .8);
    border-radius: 50%;
    background-color: #F4F6F8;
    padding: 4px;
    box-shadow: rgba(145, 158, 171, 0.16) 0px 1px 2px 0px;
}