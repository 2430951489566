.tabpanel {
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.tabpanel-body:not([hidden]) {
    overflow: hidden;
    display: flex;
    max-height: 100%;
}

@media (max-width:1024px) {
    .tabpanel-tabs button {
        flex: 1;
        border-radius: 0;
    }

    .tabpanel,
    .tabpanel-body:not([hidden]) {
        height: 100%;
    }
}


@media (max-width:640px) {
    .tabpanel-tabs button {
        flex: none;
    }

    .tabpanel .MuiTabs-flexContainer {
        overflow: auto;
    }
}
