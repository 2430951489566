/** contact column /**/
.contact-info-link {
    display: flex;
    align-items: center;
    display: flex;
    align-items: center;
}

a.contact-info-link {
    text-decoration: none;
    color: var(--color-text);
}

a.contact-info-link:hover {
    text-decoration: underline dotted;
}

.contact-info-link>svg {
    font-size: 1rem;
    margin-right: .35rem;
}

/** Contacts /**/
.contacts {
    width: 100%;
    border-radius: 4px;
}

.contacts button {
    border-radius: 0;
}

.contacts>div {
    display: flex;
}

.contacts>div>div,
.contacts div {
    border-radius: 0;
}

.contacts>div>div:not(:first-child) .MuiOutlinedInput-notchedOutline {
    margin-left: -1px;
}

.contacts>div:not(:first-child)>div .MuiOutlinedInput-notchedOutline {
    margin-top: -1px;
}

.contacts>div:first-child>div:first-child,
.contacts>div:first-child>div:first-child * {
    border-top-left-radius: 4px;
}

.contacts>div:last-child>div:first-child,
.contacts>div:last-child>div:first-child * {
    border-bottom-left-radius: 4px;
}

.contacts>div:first-child>button {
    border-top-right-radius: 4px;
}

.contacts>div:last-child>button {
    border-bottom-right-radius: 4px;
}