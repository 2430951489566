@import '../../index.css';

.datatable-toolbar {
    padding: 1rem 1rem 0;
    /*height: 4.5rem;*/
    display: flex;
    flex-direction: row;
    /*align-items: center;*/
}

.button-empty-text {
    min-width: none !important;
}

    .button-empty-text .MuiButton-startIcon {
        margin: 0;
    }

.MuiTableRow-root:hover a[data-route] {
    text-decoration: underline;
    position: relative;
}

.MuiTableRow-root .routerlink-icon {
    display: none;
    height: 0.75em;
    width: 0.75em;
    position: absolute;
    right: -.75em;
    top: calc(50% - 0.375em);
}

.MuiTableRow-root a[data-route]:hover .routerlink-icon {
    display: block;
}

.datatable-hidden {
    display: none;
}

.datatable-compact .button-add {
    position: fixed !important;
    bottom: calc(3rem + env(safe-area-inset-bottom));
    right: 1rem;
    z-index: 1;
    font-size: 0 !important;
    width: 4rem;
    height: 4rem;
    border-radius: 50% !important;
}

    .datatable-compact .button-add .MuiButton-startIcon {
        margin: 0;
    }

.datatable-compact thead th:not(:first-child) {
    display: none;
}

.datatable-compact tbody tr {
    display: grid;
    position: relative;
}

    .datatable-compact tbody tr > td:not(:last-child) {
        border: none;
    }

    .datatable-compact tbody tr > td:not(:first-child) {
        display: flex;
        align-items: center;
    }

    .datatable-compact tbody tr > td:last-child {
        padding-bottom: .5rem;
    }

    .datatable-compact tbody tr.Mui-selected::before {
        content: '';
        position: absolute;
        height: 100%;
        width: 0px;
        background-color: red;
    }
